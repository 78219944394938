
:root
{
    --primary-color: rgb(0, 130, 31);
    --secondary-color: rgb(222, 247, 229);
    --text-color: rgb(0,0,0);
    --field-color: rgb(204, 204, 204);
}
body
{
    width: 100%;
}

.quiz-question
{
    font-size: 34px;
    color: var(--text-color);
    font-weight: bold;
    width: 70%;
}
fieldset
{
    width: 42%;
    margin-top: 70px;
    overflow: hidden;
}
.input-field 
{
    display: flex;
    cursor: pointer;
    margin-bottom: 25px;
}
.radio-field
{
    position: relative;
    width: 85%;
    height: 85px;
    display: grid;
    align-content: center;
    margin-left: auto;

}
.radio-field input
{
    -webkit-appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: var(--field-color);
    cursor: pointer;
    transition: 0.5s;
}
.radio-field label
{
    pointer-events: none;
    position: relative;
    z-index: 10;
    font-size: 27px;
    color: var(--text-color);
    font-weight: bold;
    margin-left: 50px;
    transition: 0.5s;
}
.input-field .option
{
    height: 100%;
    width: 65px;
    background-color: var(--field-color);
    border-radius: 6px;
    transition: 0.5s;
    height: 84px;
    font-size: 27px;
    color: rgb(1, 1, 1);
    font-weight: bold;
    display: grid;
    place-content: center;
}
.input-field.active .option
{
    background-color: var(--primary-color);
    color: rgb(255,255,255);
}
.input-field.active .radio-field input
{
    background-color: var(--primary-color);
}
.input-field.active .radio-field label
{
    color: rgb(255,255,255);
}
.thinking_bro
{
    position: absolute;
    bottom: 0;
    right: 70px;
    width: 30%;
}

/* large screens */
@media (min-width: 1600px)
{
    .container
    {
        max-width: 1500px;
    }
}


/* Desktops/Laptops */
@media (max-width: 1200px)
{
    .thinking_bro
    {
        display: none;
    }
    .quiz-question
    {
        font-size: 35px;
    }
    fieldset
    {
        width: 70%;
    }
}

/* Laptops */
@media (max-width: 1024px)
{

}

/* Tablets */
@media (max-width: 768px)
{
    .step-inner
    {
        padding: 60px;
    }
        .quiz-question
    {
        font-size: 35px;
        width: 90%;
    }
    fieldset
    {
        width: 90%;
    }

}

/* mobile */
@media (max-width: 576px)
{
    .timer
    {
        display: none;
    }
    .step-inner
    {
        padding: 20px;
    }
    .quiz-question
    {
        width: 100%;
        font-size: 25px;
    }
    fieldset
    {
        width: 100%;
    }
    .radio-field, .input-field .option
    {
        height: 70px;
    }
    .radio-field input, .input-field .option
    {
        border-radius: 0;
    }
    .radio-field label, .input-field .option
    {
        font-size: 20px;
    }
}

/* Animation */
.bounce-left
{
    animation: bounceleft 0.6s forwards linear;
    opacity: 0;
}
@keyframes bounceleft
{
    0%
    {
        opacity: 0;
        transform: translateY(-2000px);
     }
     60%
     {
        opacity: 1;
        transform: translateY(30px);
     }
     80%
     {
        opacity: 1;
        transform: translateY(-10px);
     }
     100%
     {
        opacity: 1;
        transform: translateY(0);
     }
}

.delay-100
{
    animation-delay: 100ms;
}
