.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
    padding: 20px;
}

.mobile-logo-text{
    font-size: 5vw; 
    font-family: 'Philosopher', sans-serif !important;
}

.logo-fs{
    font-size: 3vw;
}

.app-header{
    border-bottom: 0!important;
}

.users-card{
    background-color: #F1416C;
   /* background-image:url('assets/media/patterns/vector-1.png')*/
}

.inactive-bg-color{
    background-color: #E4E6EF;
}

.input-pre-text {
  display: inline-block;
}
 .input-error-text {
  bottom: 4px !important;
  font-size: 14px;
  color: #ff0000;
  position: relative;
}

.fs-35{
    font-size:35px;
}

.btn-no-bg{
    background:none;
    border:none;
}

.p-40{
    padding:40px;
}

 .progressbar-title{
      color: #ffffff;
 } 
   .progressbar-title h3{
        display: inline-block;
      }
   .progressbar-title .percent{
        position: absolute;
        right: 5px;
      }
      .progressblue{background-color:#1199ff;}
       .bar-container{
      background: #555;
      position: relative;
      width: 100%;
      height: 5px;
      margin-top: 5px;
      display: block;
      @include border-radius(5px);
       }
      
      .progressbar{
        position:absolute;
        width:0%;
        height: 100%;
        @include border-radius(5px);

        -webkit-animation-duration: 2s;
        animation-duration: 2s;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }

       #progress-css{
        -webkit-animation-name: progress-css;
        animation-name: progress-css;
        -webkit-animation-delay: .7s*2;
        animation-delay: .7s*2;
      }

 .menu-title-span{
    color: black !important;
    font-weight: 500 !important;
    font-size: 15px !important;
 }
 
 
 .br-none{
     border-right: none !important;
 }

 .report-circle {
    display: inline-block;
    padding: 2px 0 5px 0px !important;
    margin-top: 2px;
    width: 30px;
    height: 30px !important;
    background-color: #c3bebe !important;
    border-radius: 50%;
}

.report-circle-active {
    display: inline-block;
    padding: 2px 0 5px 0px !important;
    margin-top: 2px;
    width: 30px;
    height: 30px !important;
    background-color: #285dad !important;
    border-radius: 50%;
    color:white;
}

.text-right{
    text-align: right;
}

.ml-30{
  margin-left:30px;
}

.mr-20{
  margin-right:20px;
}

.btn-right{
  float: right;
}

.mt-30{
  margin-top:30px;
}

.mt-100{
  margin-top:100px;
}

.pb-30{
  padding-bottom:30px!important;
}

.table-padding{
  padding:2rem 3rem;
}

.overview-card{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
    min-height: 70px;
    background-color: transparent;
    padding: 0 2.25rem;
    border-bottom: 1px solid #f1f1f4;
}

table .packageList{
  width: 100%;
  border: 1px solid #000;
}
th.package{
  width: 30%
}

.btn-primary{
  background-color: #005268 !important;
}

.app-header{
  background-color: #f2f5f5 !important;
}

.app-header-menu .menu .menu-item .menu-link.active .menu-title {
  color: #005268;
  font-weight: 900;
  font-size: 15px;
}


  .nav__menu {
    position: fixed;
    top: 0;
    right: -100%;
    background-color: white;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    width: 80%;
    height: 100%;
    padding: 6rem 3rem 0;
    transition: right 0.4s;
  }

  .menu-close-btn{
    text-align: center;
    position: absolute;
    top: 30px;
    right: 40px;
    cursor: pointer;
  }

  .side-menu-bg{
    width: 100%;
    background: #ffffff;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1032;
    height: 100%;
    padding: 30px;
  }

  .plr-20{
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  @media screen and (max-width:990px){
    .width-50{
     width: 90% !important;
    }
    .pl20{
      padding-left: 20px !important;
    }
 }

 .pl20{
  padding-left: 0;
}
 .width-50{
    width: 50% ;
   }

   @media screen and (min-width: 1100px) and (max-width: 1450px) {
  .show-hide-pswd-eye {
    bottom: 30px !important;
  }
  
  .show-hide-pswd-eye img {
    width: 20px;
  }
}

  .show-hide-pswd-eye {
    display: block;
    position: relative;
    float: right;
    right: 10px;
    bottom: 32px;
    cursor: pointer;
  }

  .mb-80{
    margin-bottom: 80px !important;
  }

  .app-footer{
    height: 30px !important;
  }

  .footer-text{
    font-size: 10px;
    color: black !important;
    font-weight: 400;
  }

  .tooltip {
    background-color: grey !important;
    color: white !important;
  }

  .coupon-btn{
    color: #005268;
    font-weight: 600;
  }
    .app-container-body{
    min-height: 500px;
    margin-bottom: 40px;
  }

  .font-10{
    font-size: 10px;
  }

 



