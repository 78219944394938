
  [class*=" lni-"],
  [class^="lni-"] {
    font-family: LineIcons !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .lni-add-file:before {
    content: "\e900";
  }
  .lni-empty-file:before {
    content: "\e916";
  }
  .lni-remove-file:before {
    content: "\e917";
  }
  .lni-files:before {
    content: "\ea6f";
  }
  .lni-display-alt:before {
    content: "\e901";
  }
  .lni-laptop-phone:before {
    content: "\e902";
  }
  .lni-laptop:before {
    content: "\e936";
  }
  .lni-mobile:before {
    content: "\e907";
  }
  .lni-tab:before {
    content: "\e922";
  }
  .lni-timer:before {
    content: "\e903";
  }
  .lni-headphone:before {
    content: "\e904";
  }
  .lni-rocket:before {
    content: "\e905";
  }
  .lni-package:before {
    content: "\e906";
  }
  .lni-popup:before {
    content: "\e909";
  }
  .lni-scroll-down:before {
    content: "\e90a";
  }
  .lni-pagination:before {
    content: "\e90b";
  }
  .lni-unlock:before {
    content: "\e90c";
  }
  .lni-lock:before {
    content: "\e946";
  }
  .lni-reload:before {
    content: "\e90d";
  }
  .lni-map-marker:before {
    content: "\e91e";
  }
  .lni-map:before {
    content: "\e90e";
  }
  .lni-game:before {
    content: "\e90f";
  }
  .lni-search:before {
    content: "\e910";
  }
  .lni-alarm:before {
    content: "\e911";
  }
  .lni-code:before {
    content: "\e91c";
  }
  .lni-website:before {
    content: "\e908";
  }
  .lni-code-alt:before {
    content: "\e912";
  }
  .lni-display:before {
    content: "\e913";
  }
  .lni-shortcode:before {
    content: "\e919";
  }
  .lni-headphone-alt:before {
    content: "\e915";
  }
  .lni-alarm-clock:before {
    content: "\e918";
  }
  .lni-bookmark-alt:before {
    content: "\e91a";
  }
  .lni-bookmark:before {
    content: "\e91b";
  }
  .lni-layout:before {
    content: "\e937";
  }
  .lni-keyboard:before {
    content: "\e91d";
  }
  .lni-grid-alt:before {
    content: "\e91f";
  }
  .lni-grid:before {
    content: "\e920";
  }
  .lni-mic:before {
    content: "\e921";
  }
  .lni-signal:before {
    content: "\e923";
  }
  .lni-download:before {
    content: "\e925";
  }
  .lni-upload:before {
    content: "\e926";
  }
  .lni-zip:before {
    content: "\e927";
  }
  .lni-rss-feed:before {
    content: "\e928";
  }
  .lni-warning:before {
    content: "\e929";
  }
  .lni-cloud-sync:before {
    content: "\e92a";
  }
  .lni-cloud-upload:before {
    content: "\e92b";
  }
  .lni-cloud-check:before {
    content: "\e92c";
  }
  .lni-cloud-download:before {
    content: "\e914";
  }
  .lni-cog:before {
    content: "\e92d";
  }
  .lni-dashboard:before {
    content: "\e92e";
  }
  .lni-folder:before {
    content: "\eaa0";
  }
  .lni-database:before {
    content: "\e92f";
  }
  .lni-harddrive:before {
    content: "\eaab";
  }
  .lni-control-panel:before {
    content: "\e930";
  }
  .lni-plug:before {
    content: "\e931";
  }
  .lni-menu:before {
    content: "\eab8";
  }
  .lni-power-switch:before {
    content: "\e932";
  }
  .lni-printer:before {
    content: "\e933";
  }
  .lni-save:before {
    content: "\eab9";
  }
  .lni-layers:before {
    content: "\e934";
  }
  .lni-link:before {
    content: "\e935";
  }
  .lni-share:before {
    content: "\eaba";
  }
  .lni-inbox:before {
    content: "\eabb";
  }
  .lni-unlink:before {
    content: "\e924";
  }
  .lni-microphone:before {
    content: "\e938";
  }
  .lni-magnet:before {
    content: "\e939";
  }
  .lni-mouse:before {
    content: "\e93a";
  }
  .lni-share-alt:before {
    content: "\e93b";
  }
  .lni-bluetooth:before {
    content: "\e93c";
  }
  .lni-crop:before {
    content: "\e93d";
  }
  .lni-cut:before {
    content: "\e93f";
  }
  .lni-protection:before {
    content: "\e940";
  }
  .lni-shield:before {
    content: "\eabc";
  }
  .lni-bolt-alt:before {
    content: "\e941";
  }
  .lni-bolt:before {
    content: "\e942";
  }
  .lni-infinite:before {
    content: "\e943";
  }
  .lni-hand:before {
    content: "\e944";
  }
  .lni-flag:before {
    content: "\e945";
  }
  .lni-zoom-out:before {
    content: "\e947";
  }
  .lni-zoom-in:before {
    content: "\e948";
  }
  .lni-pin-alt:before {
    content: "\e949";
  }
  .lni-pin:before {
    content: "\e9ba";
  }
  .lni-more-alt:before {
    content: "\e94a";
  }
  .lni-more:before {
    content: "\e958";
  }
  .lni-check-box:before {
    content: "\e94b";
  }
  .lni-check-mark-circle:before {
    content: "\e94c";
  }
  .lni-cross-circle:before {
    content: "\e94d";
  }
  .lni-circle-minus:before {
    content: "\e94e";
  }
  .lni-close:before {
    content: "\e951";
  }
  .lni-star-filled:before {
    content: "\e94f";
  }
  .lni-star:before {
    content: "\e950";
  }
  .lni-star-empty:before {
    content: "\e952";
  }
  .lni-star-half:before {
    content: "\e953";
  }
  .lni-question-circle:before {
    content: "\e954";
  }
  .lni-thumbs-down:before {
    content: "\e955";
  }
  .lni-thumbs-up:before {
    content: "\e956";
  }
  .lni-minus:before {
    content: "\e957";
  }
  .lni-plus:before {
    content: "\e959";
  }
  .lni-ban:before {
    content: "\e95a";
  }
  .lni-hourglass:before {
    content: "\eabd";
  }
  .lni-trash:before {
    content: "\e95b";
  }
  .lni-key:before {
    content: "\e95c";
  }
  .lni-pulse:before {
    content: "\e95d";
  }
  .lni-heart:before {
    content: "\e95e";
  }
  .lni-heart-filled:before {
    content: "\e93e";
  }
  .lni-help:before {
    content: "\e95f";
  }
  .lni-paint-roller:before {
    content: "\e960";
  }
  .lni-ux:before {
    content: "\e961";
  }
  .lni-radio-button:before {
    content: "\e962";
  }
  .lni-brush-alt:before {
    content: "\e963";
  }
  .lni-select:before {
    content: "\eabe";
  }
  .lni-slice:before {
    content: "\e964";
  }
  .lni-move:before {
    content: "\e965";
  }
  .lni-wheelchair:before {
    content: "\e966";
  }
  .lni-vector:before {
    content: "\e967";
  }
  .lni-ruler-pencil:before {
    content: "\e968";
  }
  .lni-ruler:before {
    content: "\e969";
  }
  .lni-brush:before {
    content: "\e96a";
  }
  .lni-eraser:before {
    content: "\e96b";
  }
  .lni-ruler-alt:before {
    content: "\e96c";
  }
  .lni-color-pallet:before {
    content: "\e96d";
  }
  .lni-paint-bucket:before {
    content: "\e96e";
  }
  .lni-bulb:before {
    content: "\e96f";
  }
  .lni-highlight-alt:before {
    content: "\e970";
  }
  .lni-highlight:before {
    content: "\e971";
  }
  .lni-handshake:before {
    content: "\e972";
  }
  .lni-briefcase:before {
    content: "\eabf";
  }
  .lni-funnel:before {
    content: "\eac0";
  }
  .lni-world:before {
    content: "\e973";
  }
  .lni-calculator:before {
    content: "\e974";
  }
  .lni-target-revenue:before {
    content: "\e975";
  }
  .lni-revenue:before {
    content: "\e976";
  }
  .lni-invention:before {
    content: "\e977";
  }
  .lni-network:before {
    content: "\e978";
  }
  .lni-credit-cards:before {
    content: "\e979";
  }
  .lni-pie-chart:before {
    content: "\e97a";
  }
  .lni-archive:before {
    content: "\e97b";
  }
  .lni-magnifier:before {
    content: "\e97c";
  }
  .lni-agenda:before {
    content: "\e97d";
  }
  .lni-tag:before {
    content: "\e97f";
  }
  .lni-target:before {
    content: "\e980";
  }
  .lni-stamp:before {
    content: "\e981";
  }
  .lni-clipboard:before {
    content: "\e982";
  }
  .lni-licencse:before {
    content: "\e983";
  }
  .lni-paperclip:before {
    content: "\e984";
  }
  .lni-stats-up:before {
    content: "\e97e";
  }
  .lni-stats-down:before {
    content: "\e985";
  }
  .lni-bar-chart:before {
    content: "\e986";
  }
  .lni-bullhorn:before {
    content: "\e987";
  }
  .lni-calendar:before {
    content: "\e988";
  }
  .lni-quotation:before {
    content: "\e989";
  }
  .lni-bus:before {
    content: "\e98a";
  }
  .lni-car-alt:before {
    content: "\e98b";
  }
  .lni-car:before {
    content: "\e98c";
  }
  .lni-train:before {
    content: "\e9a0";
  }
  .lni-train-alt:before {
    content: "\e991";
  }
  .lni-helicopter:before {
    content: "\e990";
  }
  .lni-ship:before {
    content: "\e992";
  }
  .lni-bridge:before {
    content: "\e993";
  }
  .lni-scooter:before {
    content: "\e98f";
  }
  .lni-plane:before {
    content: "\e994";
  }
  .lni-bi-cycle:before {
    content: "\e996";
  }
  .lni-postcard:before {
    content: "\e98d";
  }
  .lni-road:before {
    content: "\e98e";
  }
  .lni-envelope:before {
    content: "\e997";
  }
  .lni-reply:before {
    content: "\e998";
  }
  .lni-bubble:before {
    content: "\e995";
  }
  .lni-support:before {
    content: "\e999";
  }
  .lni-comment-reply:before {
    content: "\e99a";
  }
  .lni-pointer:before {
    content: "\e99b";
  }
  .lni-phone:before {
    content: "\e99c";
  }
  .lni-phone-handset:before {
    content: "\eac1";
  }
  .lni-comment-alt:before {
    content: "\e99e";
  }
  .lni-comment:before {
    content: "\e99f";
  }
  .lni-coffee-cup:before {
    content: "\e9a1";
  }
  .lni-home:before {
    content: "\e9a2";
  }
  .lni-gift:before {
    content: "\eac2";
  }
  .lni-thought:before {
    content: "\e9a3";
  }
  .lni-eye:before {
    content: "\eac3";
  }
  .lni-user:before {
    content: "\e9a4";
  }
  .lni-users:before {
    content: "\e9a5";
  }
  .lni-wallet:before {
    content: "\e9a6";
  }
  .lni-tshirt:before {
    content: "\e9a7";
  }
  .lni-medall-alt:before {
    content: "\e9a8";
  }
  .lni-medall:before {
    content: "\e9a9";
  }
  .lni-notepad:before {
    content: "\e9aa";
  }
  .lni-crown:before {
    content: "\e9ab";
  }
  .lni-ticket:before {
    content: "\e9ac";
  }
  .lni-ticket-alt:before {
    content: "\e9ad";
  }
  .lni-certificate:before {
    content: "\e9ae";
  }
  .lni-cup:before {
    content: "\e9af";
  }
  .lni-library:before {
    content: "\e9b0";
  }
  .lni-school-bench-alt:before {
    content: "\e9b1";
  }
  .lni-school-bench:before {
    content: "\e9b4";
  }
  .lni-microscope:before {
    content: "\e9b2";
  }
  .lni-school-compass:before {
    content: "\e9b3";
  }
  .lni-information:before {
    content: "\e9b5";
  }
  .lni-graduation:before {
    content: "\e9b6";
  }
  .lni-write:before {
    content: "\e9b7";
  }
  .lni-pencil-alt:before {
    content: "\e9b8";
  }
  .lni-pencil:before {
    content: "\e9b9";
  }
  .lni-blackboard:before {
    content: "\e9bb";
  }
  .lni-book:before {
    content: "\e9bc";
  }
  .lni-shuffle:before {
    content: "\e9bd";
  }
  .lni-gallery:before {
    content: "\eac4";
  }
  .lni-image:before {
    content: "\eac5";
  }
  .lni-volume-mute:before {
    content: "\e9be";
  }
  .lni-backward:before {
    content: "\e9bf";
  }
  .lni-forward:before {
    content: "\e9c0";
  }
  .lni-stop:before {
    content: "\e9c1";
  }
  .lni-play:before {
    content: "\e9c2";
  }
  .lni-pause:before {
    content: "\e9c3";
  }
  .lni-music:before {
    content: "\e9c4";
  }
  .lni-frame-expand:before {
    content: "\e9c5";
  }
  .lni-full-screen:before {
    content: "\eac6";
  }
  .lni-video:before {
    content: "\e9c6";
  }
  .lni-volume-high:before {
    content: "\e9c7";
  }
  .lni-volume-low:before {
    content: "\e9c8";
  }
  .lni-volume-medium:before {
    content: "\e9c9";
  }
  .lni-volume:before {
    content: "\e9ca";
  }
  .lni-camera:before {
    content: "\e9cb";
  }
  .lni-invest-monitor:before {
    content: "\e9cc";
  }
  .lni-grow:before {
    content: "\e9cd";
  }
  .lni-money-location:before {
    content: "\e9ce";
  }
  .lni-cloudnetwork:before {
    content: "\e9cf";
  }
  .lni-diamond:before {
    content: "\e9d0";
  }
  .lni-customer:before {
    content: "\e9d1";
  }
  .lni-domain:before {
    content: "\e9d2";
  }
  .lni-target-audience:before {
    content: "\e9d3";
  }
  .lni-seo:before {
    content: "\e9d4";
  }
  .lni-keyword-research:before {
    content: "\e9d5";
  }
  .lni-seo-monitoring:before {
    content: "\e9d6";
  }
  .lni-seo-consulting:before {
    content: "\e9d7";
  }
  .lni-money-protection:before {
    content: "\e9d8";
  }
  .lni-offer:before {
    content: "\e9d9";
  }
  .lni-delivery:before {
    content: "\e9da";
  }
  .lni-investment:before {
    content: "\e9db";
  }
  .lni-shopping-basket:before {
    content: "\e9dc";
  }
  .lni-coin:before {
    content: "\e9dd";
  }
  .lni-cart-full:before {
    content: "\e9de";
  }
  .lni-cart:before {
    content: "\e9df";
  }
  .lni-burger:before {
    content: "\e9e0";
  }
  .lni-restaurant:before {
    content: "\e9e1";
  }
  .lni-service:before {
    content: "\e9e2";
  }
  .lni-chef-hat:before {
    content: "\e9e3";
  }
  .lni-cake:before {
    content: "\e9e4";
  }
  .lni-pizza:before {
    content: "\e9e5";
  }
  .lni-teabag:before {
    content: "\e9e6";
  }
  .lni-dinner:before {
    content: "\e9e7";
  }
  .lni-taxi:before {
    content: "\e9e8";
  }
  .lni-caravan:before {
    content: "\e9e9";
  }
  .lni-pyramids:before {
    content: "\e9ea";
  }
  .lni-surfboard:before {
    content: "\e9eb";
  }
  .lni-travel:before {
    content: "\e9ec";
  }
  .lni-island:before {
    content: "\e9ed";
  }
  .lni-mashroom:before {
    content: "\e9ee";
  }
  .lni-sprout:before {
    content: "\e9ef";
  }
  .lni-tree:before {
    content: "\e9f0";
  }
  .lni-trees:before {
    content: "\e9f1";
  }
  .lni-flower:before {
    content: "\e9f2";
  }
  .lni-bug:before {
    content: "\e9f3";
  }
  .lni-leaf:before {
    content: "\e9f4";
  }
  .lni-fresh-juice:before {
    content: "\e9f5";
  }
  .lni-heart-monitor:before {
    content: "\e9f6";
  }
  .lni-dumbbell:before {
    content: "\e9f7";
  }
  .lni-skipping-rope:before {
    content: "\e9f8";
  }
  .lni-slim:before {
    content: "\e9f9";
  }
  .lni-weight:before {
    content: "\e9fa";
  }
  .lni-basketball:before {
    content: "\e9fb";
  }
  .lni-first-aid:before {
    content: "\e9fc";
  }
  .lni-ambulance:before {
    content: "\e9fd";
  }
  .lni-hospital:before {
    content: "\e9fe";
  }
  .lni-syringe:before {
    content: "\e9ff";
  }
  .lni-capsule:before {
    content: "\ea00";
  }
  .lni-stethoscope:before {
    content: "\ea01";
  }
  .lni-wheelbarrow:before {
    content: "\ea02";
  }
  .lni-shovel:before {
    content: "\ea03";
  }
  .lni-construction-hammer:before {
    content: "\ea04";
  }
  .lni-brick:before {
    content: "\ea05";
  }
  .lni-hammer:before {
    content: "\eac7";
  }
  .lni-helmet:before {
    content: "\ea06";
  }
  .lni-trowel:before {
    content: "\ea07";
  }
  .lni-construction:before {
    content: "\ea08";
  }
  .lni-apartment:before {
    content: "\ea09";
  }
  .lni-juice:before {
    content: "\ea0a";
  }
  .lni-spray:before {
    content: "\ea0b";
  }
  .lni-candy-cane:before {
    content: "\ea0c";
  }
  .lni-candy:before {
    content: "\ea0d";
  }
  .lni-fireworks:before {
    content: "\ea0e";
  }
  .lni-flags:before {
    content: "\ea0f";
  }
  .lni-baloon:before {
    content: "\ea10";
  }
  .lni-cloud:before {
    content: "\ea11";
  }
  .lni-night:before {
    content: "\ea12";
  }
  .lni-cloudy-sun:before {
    content: "\ea13";
  }
  .lni-rain:before {
    content: "\ea14";
  }
  .lni-thunder:before {
    content: "\ea15";
  }
  .lni-drop:before {
    content: "\ea16";
  }
  .lni-thunder-alt:before {
    content: "\ea18";
  }
  .lni-sun:before {
    content: "\ea17";
  }
  .lni-spell-check:before {
    content: "\ea1a";
  }
  .lni-text-format:before {
    content: "\ea1b";
  }
  .lni-text-format-remove:before {
    content: "\ea1c";
  }
  .lni-italic:before {
    content: "\ea1d";
  }
  .lni-line-dotted:before {
    content: "\ea1e";
  }
  .lni-text-align-center:before {
    content: "\ea19";
  }
  .lni-text-align-left:before {
    content: "\ea20";
  }
  .lni-text-align-right:before {
    content: "\ea21";
  }
  .lni-text-align-justify:before {
    content: "\ea22";
  }
  .lni-bold:before {
    content: "\ea23";
  }
  .lni-page-break:before {
    content: "\ea24";
  }
  .lni-strikethrough:before {
    content: "\ea25";
  }
  .lni-text-size:before {
    content: "\ea26";
  }
  .lni-line-dashed:before {
    content: "\ea27";
  }
  .lni-line-double:before {
    content: "\ea28";
  }
  .lni-direction-ltr:before {
    content: "\ea29";
  }
  .lni-direction-rtl:before {
    content: "\ea2a";
  }
  .lni-list:before {
    content: "\ea2b";
  }
  .lni-line-spacing:before {
    content: "\ea2f";
  }
  .lni-sort-alpha-asc:before {
    content: "\ea1f";
  }
  .lni-sort-amount-asc:before {
    content: "\ea2c";
  }
  .lni-indent-decrease:before {
    content: "\ea2d";
  }
  .lni-indent-increase:before {
    content: "\ea2e";
  }
  .lni-pilcrow:before {
    content: "\ea30";
  }
  .lni-underline:before {
    content: "\ea31";
  }
  .lni-dollar:before {
    content: "\ea32";
  }
  .lni-rupee:before {
    content: "\ea33";
  }
  .lni-pound:before {
    content: "\ea34";
  }
  .lni-yen:before {
    content: "\ea35";
  }
  .lni-euro:before {
    content: "\ea36";
  }
  .lni-emoji-happy:before {
    content: "\ea37";
  }
  .lni-emoji-tounge:before {
    content: "\ea38";
  }
  .lni-emoji-cool:before {
    content: "\ea39";
  }
  .lni-emoji-friendly:before {
    content: "\ea3a";
  }
  .lni-emoji-neutral:before {
    content: "\ea3b";
  }
  .lni-emoji-sad:before {
    content: "\ea3c";
  }
  .lni-emoji-smile:before {
    content: "\ea3d";
  }
  .lni-emoji-suspect:before {
    content: "\ea3e";
  }
  .lni-direction-alt:before {
    content: "\ea3f";
  }
  .lni-enter:before {
    content: "\ea40";
  }
  .lni-exit-down:before {
    content: "\ea41";
  }
  .lni-exit-up:before {
    content: "\ea5f";
  }
  .lni-exit:before {
    content: "\ea42";
  }
  .lni-chevron-up:before {
    content: "\ea43";
  }
  .lni-chevron-left:before {
    content: "\ea44";
  }
  .lni-chevron-down:before {
    content: "\ea45";
  }
  .lni-chevron-right:before {
    content: "\ea46";
  }
  .lni-arrow-down:before {
    content: "\ea47";
  }
  .lni-arrows-horizontal:before {
    content: "\ea48";
  }
  .lni-arrows-vertical:before {
    content: "\ea49";
  }
  .lni-direction:before {
    content: "\ea4a";
  }
  .lni-arrow-left:before {
    content: "\ea4b";
  }
  .lni-arrow-right:before {
    content: "\ea4c";
  }
  .lni-arrow-up:before {
    content: "\ea4d";
  }
  .lni-arrow-down-circle:before {
    content: "\ea4e";
  }
  .lni-anchor:before {
    content: "\ea4f";
  }
  .lni-arrow-left-circle:before {
    content: "\ea50";
  }
  .lni-arrow-right-circle:before {
    content: "\ea51";
  }
  .lni-arrow-up-circle:before {
    content: "\ea52";
  }
  .lni-angle-double-down:before {
    content: "\ea53";
  }
  .lni-angle-double-left:before {
    content: "\ea54";
  }
  .lni-angle-double-right:before {
    content: "\ea55";
  }
  .lni-angle-double-up:before {
    content: "\ea56";
  }
  .lni-arrow-top-left:before {
    content: "\ea57";
  }
  .lni-arrow-top-right:before {
    content: "\ea58";
  }
  .lni-chevron-down-circle:before {
    content: "\ea59";
  }
  .lni-chevron-left-circle:before {
    content: "\ea5a";
  }
  .lni-chevron-right-circle:before {
    content: "\ea5b";
  }
  .lni-chevron-up-circle:before {
    content: "\ea5c";
  }
  .lni-shift-left:before {
    content: "\ea5d";
  }
  .lni-shift-right:before {
    content: "\ea5e";
  }
  .lni-pointer-down:before {
    content: "\ea60";
  }
  .lni-pointer-right:before {
    content: "\ea62";
  }
  .lni-pointer-left:before {
    content: "\ea61";
  }
  .lni-pointer-up:before {
    content: "\ea63";
  }
  .lni-spinner-arrow:before {
    content: "\ea64";
  }
  .lni-spinner-solid:before {
    content: "\ea65";
  }
  .lni-spinner:before {
    content: "\ea66";
  }
  .lni-google:before {
    content: "\e99d";
  }
  .lni-producthunt:before {
    content: "\ea68";
  }
  .lni-paypal:before {
    content: "\eab3";
  }
  .lni-paypal-original:before {
    content: "\ea6c";
  }
  .lni-java:before {
    content: "\ea6d";
  }
  .lni-microsoft:before {
    content: "\ea6b";
  }
  .lni-windows:before {
    content: "\ea6e";
  }
  .lni-flickr:before {
    content: "\ea70";
  }
  .lni-drupal:before {
    content: "\ea9f";
  }
  .lni-drupal-original:before {
    content: "\ea71";
  }
  .lni-android:before {
    content: "\eab7";
  }
  .lni-android-original:before {
    content: "\ea72";
  }
  .lni-playstore:before {
    content: "\eab4";
  }
  .lni-git:before {
    content: "\ea9b";
  }
  .lni-github-original:before {
    content: "\ea73";
  }
  .lni-github:before {
    content: "\ea9c";
  }
  .lni-steam:before {
    content: "\ea75";
  }
  .lni-shopify:before {
    content: "\ea76";
  }
  .lni-snapchat:before {
    content: "\ea77";
  }
  .lni-soundcloud:before {
    content: "\eab5";
  }
  .lni-souncloud-original:before {
    content: "\ea78";
  }
  .lni-telegram:before {
    content: "\ea79";
  }
  .lni-twitch:before {
    content: "\ea7a";
  }
  .lni-vimeo:before {
    content: "\ea7c";
  }
  .lni-vk:before {
    content: "\ea7d";
  }
  .lni-wechat:before {
    content: "\ea7e";
  }
  .lni-whatsapp:before {
    content: "\ea7f";
  }
  .lni-yahoo:before {
    content: "\ea80";
  }
  .lni-youtube:before {
    content: "\ea81";
  }
  .lni-stackoverflow:before {
    content: "\ea82";
  }
  .lni-slideshare:before {
    content: "\ea84";
  }
  .lni-slack:before {
    content: "\ea85";
  }
  .lni-lineicons-alt:before {
    content: "\ea69";
  }
  .lni-lineicons:before {
    content: "\ea6a";
  }
  .lni-skype:before {
    content: "\ea86";
  }
  .lni-pinterest:before {
    content: "\ea87";
  }
  .lni-reddit:before {
    content: "\ea88";
  }
  .lni-line:before {
    content: "\ea89";
  }
  .lni-megento:before {
    content: "\ea8b";
  }
  .lni-blogger:before {
    content: "\ea8e";
  }
  .lni-bootstrap:before {
    content: "\ea8f";
  }
  .lni-dribbble:before {
    content: "\ea90";
  }
  .lni-dropbox:before {
    content: "\ea98";
  }
  .lni-dropbox-original:before {
    content: "\ea91";
  }
  .lni-envato:before {
    content: "\ea92";
  }
  .lni-500px:before {
    content: "\ea95";
  }
  .lni-twitter-original:before {
    content: "\ea7b";
  }
  .lni-twitter:before {
    content: "\ea97";
  }
  .lni-twitter-filled:before {
    content: "\eac8";
  }
  .lni-facebook-messenger:before {
    content: "\ea93";
  }
  .lni-facebook-original:before {
    content: "\ea94";
  }
  .lni-facebook-filled:before {
    content: "\ea99";
  }
  .lni-facebook:before {
    content: "\ea9a";
  }
  .lni-joomla:before {
    content: "\eac9";
  }
  .lni-firefox:before {
    content: "\ea9d";
  }
  .lni-amazon-original:before {
    content: "\ea74";
  }
  .lni-amazon:before {
    content: "\ea9e";
  }
  .lni-linkedin-original:before {
    content: "\ea8a";
  }
  .lni-linkedin:before {
    content: "\eaa1";
  }
  .lni-linkedin-filled:before {
    content: "\eaca";
  }
  .lni-bitbucket:before {
    content: "\eaa2";
  }
  .lni-quora:before {
    content: "\eaa3";
  }
  .lni-medium:before {
    content: "\eaa4";
  }
  .lni-instagram-original:before {
    content: "\ea8c";
  }
  .lni-instagram-filled:before {
    content: "\eaa6";
  }
  .lni-instagram:before {
    content: "\eaa7";
  }
  .lni-bitcoin:before {
    content: "\eaa8";
  }
  .lni-stripe:before {
    content: "\eaa9";
  }
  .lni-wordpress-filled:before {
    content: "\eaaa";
  }
  .lni-wordpress:before {
    content: "\ea96";
  }
  .lni-google-plus:before {
    content: "\eaac";
  }
  .lni-mastercard:before {
    content: "\eaae";
  }
  .lni-visa:before {
    content: "\eaaf";
  }
  .lni-amex:before {
    content: "\eacb";
  }
  .lni-apple:before {
    content: "\eab0";
  }
  .lni-behance:before {
    content: "\eab1";
  }
  .lni-behance-original:before {
    content: "\ea8d";
  }
  .lni-chrome:before {
    content: "\eab2";
  }
  .lni-spotify-original:before {
    content: "\ea83";
  }
  .lni-spotify:before {
    content: "\eab6";
  }
  .lni-html:before {
    content: "\eaad";
  }
  .lni-css:before {
    content: "\eaa5";
  }
  .lni-ycombinator:before {
    content: "\ea67";
  }
  span {
    display: inline-block;
  }
  .size-xs {
    font-size: 1rem !important;
  }
  .size-sm {
    font-size: 1.55rem !important;
  }
  .size-md {
    font-size: 2.25rem !important;
  }
  .size-fw {
    font-size: 1.2857142857em;
    line-height: 0.8em;
    width: 1.2857142857em;
    height: 0.8em;
    margin-top: -0.2em !important;
    vertical-align: middle;
  }
  .size-lg {
    font-size: 3rem !important;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @-webkit-keyframes burst {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
    90% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
      opacity: 0;
    }
  }
  @keyframes burst {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
    90% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
      opacity: 0;
    }
  }
  @-webkit-keyframes flashing {
    0%,
    90% {
      opacity: 1;
    }
    45% {
      opacity: 0;
    }
  }
  @keyframes flashing {
    0%,
    90% {
      opacity: 1;
    }
    45% {
      opacity: 0;
    }
  }
  @-webkit-keyframes fade-left {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
    75% {
      -webkit-transform: translateX(-20px);
      transform: translateX(-20px);
      opacity: 0;
    }
  }
  @keyframes fade-left {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
    75% {
      -webkit-transform: translateX(-20px);
      transform: translateX(-20px);
      opacity: 0;
    }
  }
  @-webkit-keyframes fade-right {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
    75% {
      -webkit-transform: translateX(20px);
      transform: translateX(20px);
      opacity: 0;
    }
  }
  @keyframes fade-right {
    0% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
    75% {
      -webkit-transform: translateX(20px);
      transform: translateX(20px);
      opacity: 0;
    }
  }
  @-webkit-keyframes fade-up {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
    75% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      opacity: 0;
    }
  }
  @keyframes fade-up {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
    75% {
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      opacity: 0;
    }
  }
  @-webkit-keyframes fade-down {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
    75% {
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
      opacity: 0;
    }
  }
  @keyframes fade-down {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
    75% {
      -webkit-transform: translateY(20px);
      transform: translateY(20px);
      opacity: 0;
    }
  }
  @-webkit-keyframes tada {
    from,
    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    10%,
    20% {
      -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
      transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
    }
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
      transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    }
    40%,
    60%,
    80% {
      -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
      transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg);
    }
  }
  @keyframes tada {
    from,
    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    10%,
    20% {
      -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
      transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
    }
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
      transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    }
    40%,
    60%,
    80% {
      -webkit-transform: rotate3d(0, 0, 1, -10deg);
      transform: rotate3d(0, 0, 1, -10deg);
    }
  }
  .lni-spin-effect,
  .lni-spin-effect-hover:hover {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  .lni-tada-effect,
  .lni-tada-effect-hover:hover {
    -webkit-animation: tada 1.5s ease infinite;
    animation: tada 1.5s ease infinite;
  }
  .lni-flashing-effect,
  .lni-flashing-effect-hover:hover {
    -webkit-animation: flashing 1.5s infinite linear;
    animation: flashing 1.5s infinite linear;
  }
  .lni-burst-effect,
  .lni-burst-effect-hover:hover {
    -webkit-animation: burst 1.5s infinite linear;
    animation: burst 1.5s infinite linear;
  }
  .lni-fade-up-effect,
  .lni-fade-up-effect-hover:hover {
    -webkit-animation: fade-up 1.5s infinite linear;
    animation: fade-up 1.5s infinite linear;
  }
  .lni-fade-down-effect,
  .lni-fade-down-effect-hover:hover {
    -webkit-animation: fade-down 1.5s infinite linear;
    animation: fade-down 1.5s infinite linear;
  }
  .lni-fade-left-effect,
  .lni-fade-left-effect-hover:hover {
    -webkit-animation: fade-left 1.5s infinite linear;
    animation: fade-left 1.5s infinite linear;
  }
  .lni-fade-right-effect,
  .lni-fade-right-effect-hover:hover {
    -webkit-animation: fade-right 1.5s infinite linear;
    animation: fade-right 1.5s infinite linear;
  }

 