.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login-bg{
   /* background-image: url('../../assets/images/auth-bg.png');*/
   background-color: #419eac;
}
.link-primary{
    color: #419eac !important;
    text-decoration-color:#419eac !important;
  }

  .link-primary:focus,.link-primary:hover {
    color: #005268 !important;
    text-decoration-color:#005268 !important;
  }
  .signup-link{
    text-decoration: underline;
  }
  .signup-mt{
    margin-top: 10px;
  }
  
  @media screen and (max-width:990px){
    .left-image{
  -webkit-order: 1;
  order: 1 !important;
  display: none !important;
}

  .right-text{
  -webkit-order: 2;
  order: 2 !important;    
}
}

.expert-signup-btn{
  border: 1px solid #dbd4d4;
    padding: 10px;
    margin-top: 40px;
}

.google-login-btn{
  border: 1px solid #dbd4d4;
    padding: 10px;
    margin-top: 10px;
}

.ml-30{
  margin-left: 30px;
}

.mt-30{
  margin-top: 30px;
}

.m-30{
  margin: 30px;
}

.mt-20{
  margin-top: 20px !important;
}

.p-15{
  padding:15px;
}

.mb-30{
  margin-bottom: 30px;
}

.ml-10{
  margin-left: 10px;
}

