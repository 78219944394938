:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace
}

*,::after,::before {
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

p {
    margin: 0 0 25px;
}

a, h1, h2, h3, h4, h5, h6, p, span {
    overflow-wrap: break-word;
}
.services {
    padding: 30px 0;
  }
  
  .services .service-item {
    background: #fff;
    height: 100%;
    border-radius: 10px;
  }
  
  .services .service-item .icon {
    width: 48px;
    height: 48px;
    position: relative;
    margin-bottom: 50px;
  }
  
  .services .service-item .icon i {
    color: var(--color-default);
    font-size: 56px;
    transition: ease-in-out 0.3s;
    z-index: 2;
    position: relative;
  }
  
  .services .service-item .icon:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: #eeeeee;
    border-radius: 50px;
    z-index: 1;
    top: 10px;
    right: -20px;
    transition: 0.3s;
  }
  
  .services .service-item h3 {
    color: var(--color-default);
    font-weight: 700;
    margin: 0 0 20px 0;
    padding-bottom: 8px;
    font-size: 22px;
    position: relative;
    display: inline-block;
    border-bottom: 4px solid #eeeeee;
    transition: 0.3s;
  }
  
  .services .service-item p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }

  .services p {
    font-size: 16px;
  }
  
  .services .service-item .readmore {
    margin-top: 15px;
    display: inline-block;
    color: #419eac;
  }
  
  .services .service-item:hover .icon:before {
    background: #00b6a1;
  }
  
  .services .service-item:hover h3 {
    border-color: var(--color-primary);
  }

  .readMoreBtn {
    border: none;
    background: none;
    font-size: 16px;
}

img {
    vertical-align: middle;
    border-style: none;
}

.img-fluid {
    max-width: 100%;
}
  

.card-img-top {
    object-fit: cover;
    height: 100%;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
}

.header-appear .navbar-top-default {
    width: 100%;
    height: 70px;
    position: fixed;
    z-index: 99;
    top: 0;
    background: #ffffff;
    animation-name: animationFade;
    animation-duration: 1s;
    animation-fill-mode: both;
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.35);
  }
  .header-appear .navbar-simple .navbar-nav .nav-item .nav-link {
    color: #002450;
  }
  .navbar-nav {
    align-items: center;
  }
  .navbar-top-default #megaone {
    margin-right: -30px;
  }
  .navbar-simple .navbar-nav .nav-item .nav-link {
    font-size: 14px;
    color: #002450;
    font-weight: bold;
    text-align: center;
    padding: 0 15px;
    transition: 0.5s ease;
    font-family: "Montserrat", sans-serif;
  }
  .navbar-simple .navbar-nav .nav-item .nav-link:hover,
  .navbar-simple .navbar-nav .nav-item.active .nav-link {
    color: #419eac;
    transition: 0.5s ease;
  }
  .header-appear .navbar-top-default {
    height: 80px;
  }

  .header-appear .sidemenu_btn span {
    background: #002450;
  }

  @media screen and (max-width: 767px) {
    .testimonial-section .text-section {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    section {
      padding: 1rem 0;
    }
    .padding-top-half {
      padding-top: 0rem;
    }
    .padding-bottom-half {
      padding-bottom: 1rem;
    }
    .heading-area .heading {
      font-size: 24px;
    }
    .blue_rectangle {
      height: 50% !important;
      width: 40% !important;
    }
    .about_img {
      height: 200px !important;
      width: 200px !important;
    }
    .mt-5 {
      margin-top: 0px !important;
    }
    .navbar-top-default {
      background: #ffffff;
      top: 0;
      height: 80px;
    }
    .header-appear .navbar-top-default {
      height: 80px;
    }
    .navigation-toggle span {
      background-color: #002450;
    }
    header a.logo img {
      max-width: 150px;
    }
    .side-menu {
      width: 100%;
    }
    .side-footer p {
      font-size: 12px;
    }
    .sidemenu_btn {
      right: 15px !important;
      top: 25px !important;
    }
    .side-nav .navbar-nav .nav-item {
      margin: 12px 0;
    }
    .side-nav .navbar-nav .nav-link {
      font-size: 18px;
    }
    .sidemenu_btn {
      display: inline-block !important;
    }
    .area-heading h2 {
      font-size: 30px;
    }
    .area-heading p {
      font-size: 14px;
    }
    .slider-area {
      display: block;
      height: auto;
      position: relative;
      padding-top: 75px;
    }
    .author-skills {
      position: relative;
      margin-top: 50px;
    }
    .about-media {
      padding-top: 50px;
    }
    .slider-image img {
      top: 30px;
    }
    .slider-area .content-margin {
      order: 2;
    }
    .slider-area .content-margin {
      margin-bottom: 0;
    }
    .slider-image {
      margin: 0;
      top: 0;
    }
    #particles-js {
      display: block;
      height: auto;
    }
    .about-heading .heading {
      font-size: 24px;
    }
    .about-heading .text-small {
      font-size: 18px;
    }
    .about-heading .info {
      font-size: 16px;
    }
    .stats-sec .circular-wrap > div {
      padding-bottom: 90px;
    }
    .stats-sec .circular-wrap > div:nth-child(3) {
      padding-bottom: 0;
    }
    .circle canvas {
      height: 160px !important;
      width: 160px !important;
    }
    .stats-sec .circular-wrap p {
      font-size: 16px;
    }
    .stats-sec .circular-wrap h4 {
      font-size: 22px;
    }
    .price-item,
    .price-item-center {
      margin: 40px 0;
    }
    .hosting .img-fluid {
      margin-top: 60px;
    }
    .learn-more-section .heading-area .heading {
      font-size: 22px;
    }
    .testimonial-section .text-section .text-area {
      padding: 0;
    }
    .testimonial-heading h5 {
      font-size: 24px;
    }
    .testimonial-heading h2 {
      font-size: 32px;
    }
    .carousel-section .testimonials p {
      font-size: 16px;
      width: 90%;
      text-align: center !important;
    }
    .carousel-section .testimonials h5 {
      font-size: 20px;
    }
    .testimonials {
      padding: 4rem 0;
    }
    #blog .col-lg-4:not(:nth-last-child(1)) .news-item {
      margin-bottom: 50px;
    }
    .contact-sec {
      margin-bottom: 0;
    }
    .alert-danger {
      margin-top: 30px;
    }
    .footer {
      padding: 1rem 0;
    }
    .footer .footer-social a {
      margin: 0;
    }
    .footer .company-about {
      font-size: 12px;
    }
    .stand-banner .box-content h2 {
      font-size: 34px;
    }
    .standalone-detail {
      padding-bottom: 70px;
    }
    .standalone-detail .heading {
      font-size: 42px;
    }
    .standalone-detail .para_text {
      font-size: 16px;
    }
    .standalone-area .sub-heading {
      margin-top: 3rem;
    }
    .standalone-row .stand-img-des {
      padding-left: 0;
      padding-right: 0;
    }
    .standalone-row:nth-child(2) .stand-img-des {
      padding-left: 0;
      padding-right: 0;
    }
    .standalone-row:nth-child(2) {
      margin-top: 6rem;
      margin-bottom: 6rem;
    }
    .page-title h2 {
      font-size: 2.5rem;
    }
    .standalone-area .heading-text {
      font-size: 30px;
    }
    .standalone-area .stand-img-des .para_text {
      font-size: 16px;
      padding: 0 15px;
    }
    .standalone-footer {
      padding-top: 0;
    }
  }

  .navbar-top-default {
    position: relative;
    z-index: 99;
    overflow: hidden;
    height: 70px;
    width: 100%;
    background: #ffffff;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    padding: .5rem 1rem;
}

  
  .navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }
  .navbar-brand:focus,
  .navbar-brand:hover {
    text-decoration: none;
  }
  .navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  .navbar-toggler:focus,
  .navbar-toggler:hover {
    text-decoration: none;
  }
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
  }
  .scroll {
    overflow: auto;
  }

  @media (min-width: 992px) {
    .navbar-expand-lg {
        -ms-flex-flow:row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-lg>.container,.navbar-expand-lg>.container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

.ml-auto,.mx-auto {
    margin-left: auto!important
}

dl,ol,ul {
    list-style-position: inside;
    padding: 0;
    margin: 0 0 25px
}

.form-inline .btn-primary{
    padding: .375rem .75rem;
    border-radius: inherit;
    font-size: 12px;
    font-weight: bold;
}

.twitter-icon {
    height: 16px;
}


.footer {
    padding: 0.5rem 0;
    background: #005268;
}

.footer .footer-social ul {
    margin: 0;
    padding: 0;
}

.footer .footer-social li {
    display: inline-block;
}

.footer .footer-social i {
    color: #ffffff;
}

.footer .footer-social a {
    position: relative;
    display: block;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    border-radius: 50px;
    font-size: 15px;
    margin: 0 0 0 0;
    border: 1px solid transparent;
    visibility: visible !important;
}

.footer .footer-social a i {
    position: relative;
    z-index: 11;
    transition: .3s ease;
}

.footer .footer-social li:nth-last-child(1) a {
    margin-right: 0;
}

.footer .footer-social li a span {
    position: absolute;
    left: 100%;
    right: 100%;
    bottom: 100%;
    top: 100%;
    z-index: 1;
    background: #00b7ff;
    border-radius: 50%;
    transition: .3s ease;
}

.footer .footer-social li a:hover span {
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.footer .footer-social li a:hover i {
    color: #ffffff;
    transition: .3s ease;
}

.footer .company-about {
    font-size: 15px;
    color: #002450;
    opacity: .7;
}

.footer .footer-social img {
    color: #ffffff;
}

.footer .footer-social li a img:hover {
    color: #ffffff;
    transition: .3s ease;
}

.f-right {
    float: right;
}

.f-left {
    float: left;
}

.footer a{
    font-size:16px;
}
.about-reset {
    padding: 4rem;
}

.about-reset p {
    color: black;
}

section p,span,label{
    font-size:16px;
}
#particles-js {
    display: flex;
    height: 100vh;
    ;}

.slider-image {
    position: relative;
    margin: 0 auto 30px auto;
    top: 10px;
}

.slider-image img {
    position: relative;
}

.slider-area {
    padding-top: 120px;
    position: absolute;
}

.area-heading h2 {
    font-size: 40px;
}

.area-heading p {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
}

@media (max-width: 1400px) and (min-width: 1280px) {
    .slider-area {
        padding-top: 0;
    }

    .area-heading h2 {
        font-size: 40px;
    }

    .area-heading p {
        margin-bottom: 25px!important;
    }

    .slider-image img {
        width: 70%;
    }
}
@media (min-width: 992px) {
    .text-lg-left {
        text-align:left!important
    }

    .text-lg-right {
        text-align: right!important
    }

    .text-lg-center {
        text-align: center!important
    }
}

.btn.btn-small {
    font-size: 12px;
    padding: 4px 1.8em;
}

.btn.btn-medium {
    font-size: 14px;
    padding: 12px 36px;
    line-height: 1.8em
}

.btn.btn-large {
    font-size: 16px;
    padding: 8px 35px;
    ;line-height: 1.8em;
}
.btn.btn-rounded {
    border-radius: 50px;
}
.btn.btn-trans-white {
    background: #ffffff;
    border-color: #ffffff;
    color: #005268
}

.btn.btn-trans-white:hover {
    background: #005268 !important;
    border-color: #005268;
    color: #ffffff !important
}
.bg-light-white {
    background: #f6f6f6;
}

section {
    padding: 2rem 0;
}

.padding-bottom {
    padding-bottom: 5.5rem;
}

.padding-top {
    padding-top: 5.5rem;
}

.padding-top-half {
    padding-top: 2rem;
}

.padding-bottom-half {
    padding-bottom: 1rem;
}

section .heading {
    font-size: 36px;
    font-weight: 500;
    color: #002450;
    text-align: center;
}

.img-sec {
    position: relative;
    padding-right: 50px;
    padding-bottom: 50px;
}

.about_img {
    padding: 2%;
    background-color: #f6f6f6;
    position: relative;
}

.about-heading span {
    color: black;
    font-size: 16px;
    margin-bottom: 8px;
}

.about_img img {
    height: 100%;
    width: 100%;
}

#what-is-reset .mt-15{
    margin-top:15px !important;
}

.text-justify {
    text-align: justify!important
}

.HDRW {
    margin-bottom: 30px;
    background: #419eac;
}

.HDRW h3 {
    padding: 20px 5px !important;
    font-weight: 500;
}

.reset-work span {
    color: black;
    margin-bottom: 10px;
}

.reset-work ul {
    margin-left: 30px;
    font-size: 16px;
}

.assessments-link {
    color: #005268;
    text-decoration: underline;
}

.radius-20 {
    border-radius: 20px;
}

.float-right {
    float: right !important;
}

.heading-area .heading {
    font-size: 36px;
    margin: 25px 0;
    font-weight: 600;
    color: #419eac;
    font-family: 'Montserrat', sans-serif;
}

.news-item {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    text-align: left;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.news-item img {
    width: 100%;
}

.news-item:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, .12);
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.news-item .news-text-box {
    padding: 30px;
    background-color: #f8f8f9;
    margin: 15px auto;
    border-radius: 15px;
}

.news-item .author {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    margin-bottom: 10px;
}

.news-item .author > img {
    max-width: 60px;
    border-radius: 50px;
    overflow: hidden;
    margin-right: 15px;
}

.news-item .author .author-name {
    margin: 0;
    font-size: 20px;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    font-style: italic;
    font-family: Roboto, sans-serif;
}

.news-item .news-text-box .para {
    font-size: 16px;
    color: #1d1d1f;
    text-align: justify;
}

section .mb-10{
    margin-bottom: 10px !important;
}

p:last-child {
    margin-bottom: 0 !important;
    font-family: 'Roboto', sans-serif;
}
.news-item .news-title {
    font-size: 20px;
    font-weight: 700;
    color: #002450;
    margin: 10px 0;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.news-item .author {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    margin-bottom: 10px;
}
.section-title {
    text-align: center;
    padding-bottom: 30px;
}

.section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: #37517e;
}

.contact .info {
    border-top: 3px solid #419eac;
    border-bottom: 3px solid #419eac;
    padding: 30px;
    background: #fff;
    width: 100%;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
    text-align: start;
}

.contact .info i {
    font-size: 20px;
    color: #47b2e4;
    float: left;
    width: 44px;
    height: 44px;
    background: #e7f5fb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #37517e;
}

.contact .info p {
    padding: 0 0 10px 60px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #6182ba;
}

.contact .contact-form {
    width: 100%;
    border-top: 3px solid #419eac;
    border-bottom: 3px solid #419eac;
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .contact-form .form-group {
    margin-bottom: 2px;
    text-align: start;
}

.contact .contact-form input {
    height: 44px;
}

.contact .contact-form label {
    color: #444444;
    padding-bottom: 2px;
    display: inline-block;
    margin-bottom: .5rem;
}

.contact .contact-form .sent-message {
    color: #fff;
    background: #419eac;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.error {
    color: red;
}

.price-text {
    color: black;
    font-size: 20px;
}

.sidemenu_btn {
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    width: 36px;
    padding: 6px;
    right: 40px;
    top: 35px;
    display: none;
    position: absolute;
}

@media screen and (max-width: 767px) {
    #particles-js {
        display: block;
        height: auto;
    }
        .sidemenu_btn {
            right: 15px;
            top: 25px;
            display: inline-block !important;
        }
        .slider-area {
            display: block;
            height: auto;
            position: relative;
            padding-top: 75px;
        }
        .slider-area .content-margin {
            margin-bottom: 0;
        }
        .slider-area .content-margin {
            order: 2;
        }
        .footer {
            padding: 1rem 0;
        }
        .footer .footer-social a {
            margin: 0;
        }
        .footer .company-about {
            font-size: 12px;
        }
       
}

.side-menu-active{
    background: #ffffff;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1032;
    height: 100%;
}

@media screen and (max-width: 600px) and (min-width: 380px) {
    .testimonial-section .text-section {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .slider-area h1 {
        font-size: 16px;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5), -2px -2px 4px rgba(255, 255, 255, 0.5);
    }

    .slider-area .content-margin {
        display: none;
    }
}


.side-menu .btn-close {
    height: 34px;
    display: inline-block;
    text-align: center;
    position: absolute;
    top: 30px;
    right: 40px;
    cursor: pointer;
    color: black;
}

.side-menu.before-side .btn-close {
    display: none;
}

.side-menu .btn-close::before, .side-menu .btn-close::after {
    position: absolute;
    left: 16px;
    content: ' ';
    height: 24px;
    width: 2px;
    background: #ffffff;
    top: 5px;
}

.side-menu .btn-close:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.side-menu .btn-close:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.sidemenu_btn {
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    width: 36px;
    padding: 6px;
    right: 40px;
    top: 35px;
    display: none;
    position: absolute;
}

.sidemenu_btn span {
    height: 2px;
    width: 100%;
    background: #002450;
    display: block;
    margin-left: auto;
    transition: .5s ease;
}

.header-appear .sidemenu_btn span {
    background: #002450;
}

.sidemenu_btn span:nth-child(2) {
    margin: 4px 0;
}

.sidemenu_btn:hover span:first-child, .sidemenu_btn:hover span:last-child {
    width: 70%;
    transition: .5s ease;
    margin-left: auto;
}

.side-menu .inner-wrapper {
    padding: 1rem 4rem;
    height: 100%;
    position: relative;
    overflow-y: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.pul-menu.pushwrap .side-menu .inner-wrapper {
    padding: 3.5rem 2.5rem;
}

.side-menu .side-nav {
    margin-bottom: 30px;
    height: 70%;
    display: flex;
    align-items: center;
}

.side-nav .navbar-nav .nav-item {
    display: block;
    margin: 20px 0;
    padding: 0 !important;
    opacity: 0;
    -webkit-transition: all 0.8s ease 500ms;
    -o-transition: all 0.8s ease 500ms;
    transition: all 0.8s ease 500ms;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
}

.side-nav .navbar-nav .nav-item:first-child {
    -webkit-transition-delay: .1s;
    -o-transition-delay: .1s;
    transition-delay: .1s;
}

.side-nav .navbar-nav .nav-item:nth-child(2) {
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s;
}

.side-nav .navbar-nav .nav-item:nth-child(3) {
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
}

.side-nav .navbar-nav .nav-item:nth-child(4) {
    -webkit-transition-delay: .4s;
    -o-transition-delay: .4s;
    transition-delay: .4s;
}

.side-nav .navbar-nav .nav-item:nth-child(5) {
    -webkit-transition-delay: .5s;
    -o-transition-delay: .5s;
    transition-delay: .5s;
}

.side-nav .navbar-nav .nav-item:nth-child(6) {
    -webkit-transition-delay: .6s;
    -o-transition-delay: .6s;
    transition-delay: .6s;
}

.side-nav .navbar-nav .nav-item:nth-child(7) {
    -webkit-transition-delay: .7s;
    -o-transition-delay: .7s;
    transition-delay: .7s;
}

.side-nav .navbar-nav .nav-item:nth-child(8) {
    -webkit-transition-delay: .8s;
    -o-transition-delay: .8s;
    transition-delay: .8s;
}

.side-nav .navbar-nav .nav-item:nth-child(9) {
    -webkit-transition-delay: .9s;
    -o-transition-delay: .9s;
    transition-delay: .9s;
}

.side-menu.side-menu-active .side-nav .navbar-nav .nav-item {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.side-nav .navbar-nav .nav-link {
    display: inline-table;
    color: #002450 !important;
    padding: 2px 0 3px 0 !important;
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    position: relative;
    border-radius: 0;
    font-family: 'Montserrat', sans-serif;
}

.side-nav .navbar-nav .nav-link:after {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid rgba(255, 255, 255, 0);
    bottom: -5px;
    content: " ";
    display: block;
    margin: 0 auto;
    position: relative;
    -webkit-transition: all 280ms ease-in-out;
    transition: all 280ms ease-in-out;
    width: 0;
}

.side-nav .navbar-nav .nav-link:hover:after, .side-nav .navbar-nav .nav-link.active:after {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-color: #419eac;
    -webkit-transition: width 350ms ease-in-out;
    transition: width 350ms ease-in-out;
    width: 100%;
}

.side-menu p {
    margin-top: .5rem;
    margin-bottom: 0;
}

.side-navbar.navbar-nav {
    align-items: start;
}

.side-menu.before-side {
    width: 280px;
}

@media screen and (max-width: 767px) {
    .navbar-top-default {
        background: #ffffff;
        top: 0;
        height: 80px;
    }
    #particles-js {
        display: block;
        height: auto;
    }

    .footer .mobile{
        text-align: center !important;
        width: 100%;
    }
}

.col,.col-1,.col-10,.col-11,.col-12,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-auto,.col-lg,.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-auto,.col-md,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-auto,.col-sm,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-auto,.col-xl,.col-xl-1,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-auto {
    position: relative;
    padding-right: 15px;
    padding-left: 15px
}

.btn-close .fas{
  color: black;
}

.readmore .fa{
  font-weight: 900;
  color: #419eac;
  font-size: 16px;
}

.ml-5px {
  margin-left: 5px;
}
