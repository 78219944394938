.packages-styles {
    /* border: 1px solid darkgray; */
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
    /* background-color: var(--bs-gray-100); */
}

.swal-text {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, .64);
}

.download-styles {
    border: 1px solid lightgray;
    float: right;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}
.ag-column-first span {
    justify-content: left !important;
    align-items: start !important;
}
.ag-cell-value span {
    justify-content: left !important;
    align-items: start !important;
}