[data-letters]:before {
    content:attr(data-letters);
    display:inline-block;
    font-size:1em;
    width:2.5em;
    height:2.5em;
    line-height:2.5em;
    text-align:center;
    border-radius:50%;
    background:#710594;
    vertical-align:middle;
    /* margin-right:1em; */
    color:white;
    }
.subject-shadow {
    /* box-shadow: 0px 10px 10px #ededed; */
    -webkit-box-shadow: 0 8px 6px -6px #ededed;
    -moz-box-shadow: 0 8px 6px -6px #ededed;
         box-shadow: 0 8px 6px -6px #ededed;
}
.namestyles {
    font-weight: bold;
}
.mailstyles {
    font-size: smaller;
}
.vertical-line{
    width: 1px; /* Line width */
    background-color: darkgray; /* Line color */
    height: 100%; /* Override in-line if you want specific height. */
    float: left; /* Causes the line to float to left of content. 
      You can instead use position:absolute or display:inline-block
      if this fits better with your design */
    margin: 5px 14px;
  }
.button-styles {
    float: right;
    padding: 9px 14px;
    margin-top: 10px
}