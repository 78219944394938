.package .accordion-button::after {
    display: none;
}
.package .accordion-button {
    background-color: var(--bs-primary-bg-subtle);
    transition: all .2s ease-in-out;
}

.minus-styles {
    color: var(--bs-primary) !important
}
